import Layout from "~/components/layout";
import styles from "~/styles/index.css";

export function links() {
  return [{ rel: "stylesheet", href: styles }];
}

export default function Index() {
  return (
    <Layout>
      <h1>SSO HP</h1>
    </Layout>);

}